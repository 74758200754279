<template>
    <div class="mx-auto container container--fluid">
        <v-snackbar v-model="snackbar" :top=true :color="color" :timeout=6000>
            <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>

        <v-app id="inspire" class="">
            <template v-if="load==true">
                <v-data-table :headers="headers" :items="pw_list" class="elevation-1" :search="search">
                    <template v-slot:top>
                        <v-toolbar flat color="white">
                            <v-toolbar-title>Practice Website</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                            <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details>
                            </v-text-field>
                            <span style="width: 20px"></span>
                            <v-dialog v-model="dialog" max-width="500px">
                                    <v-card>
                                    <v-card-title>
                                        <span class="headline">{{ formTitle }}</span>
                                    </v-card-title>

                                    <v-card-text>
                                        <v-container>
                                            
                                        </v-container>
                                    </v-card-text>

                                    </v-card>
                            </v-dialog>
                        </v-toolbar>
                    </template>
                    <template v-slot:item.action="{ item }">
                        <div>
                            <v-avatar style="" size="25" class="edit-avatar">
                                <v-icon class="edit-v-icon mr-2" @click="editItem(item)">edit</v-icon>
                            </v-avatar>
                        </div>
                    </template>
                </v-data-table>
            </template>
        </v-app>
    </div>
</template>

<script>
    import axios from "axios";
    export default {
        data: () => ({
            overlay: false,
            snackbar_msg: "",
            color: "",
            snackbar: false,
            iseditable: false,
            load: false,
            dialog: false,
            pw_list: null,
             errorMsg: {
                id: false
            },
            search: '',
            headers: [  {
                text: "Website Name",
                value: "website_name"
            }, 
             {
                text: "URL",
                value: "url"
            },
            {
                text: "Description",
                value: "description"
            },],

            editedIndex: -1,
            editedItem: {
                id: 0,
                website_name: '',
                url: "",
                 isactive: "",
                 description: "",
            },
            defaultItem: {
                id: 0,
                website_name: '',
                url: "",
                description: "",
                isactive: "",
            }
        }),

        computed: {
            formTitle() {
                return this.editedIndex === -1 ? "New Practice Website" : "Edit Practice Website";
            }
        },

        watch: {
            dialog(val) {
                    val || this.close();
                },
                overlay(val) {
                    val = () => {
                        this.overlay = false
                    };
                },
        },
        mounted() {

            axios
                .post("/LearnerData/addPracticeWebsite")
                .then(res => {
                    if (res.data.msg == "200") {
                        this.pw_list = res.data.pw_list
                        this.load = true;
                    } else {}
                })
                .catch(error => {
                    this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                    //window.console.log(error);
                });
        },

        methods: {
            initializeErrorMsgFalse() {
                    this.errorMsg.id = false;
                },
            editItem(item) {
                    this.iseditable = false;
                    this.editedIndex = this.pw_list.indexOf(item);
                    this.editedItem = Object.assign({}, item);
                    this.dialog = true;
                },
                showSnackbar(clr, msg) {
                    this.snackbar = true;
                    this.color = clr;
                    this.snackbar_msg = msg;
                },
                close() {
                    this.dialog = false;
                    setTimeout(() => {
                        this.editedItem = Object.assign({}, this.defaultItem);
                         this.initializeErrorMsgFalse();
                        this.editedIndex = -1;
                    }, 300);
                },

                isValidated() {
                    if (this.editedItem.id) {
                        return true;
                    } else {
                        if (!this.editedItem.id) {
                            this.errorMsg.id = true
                        }

                        return false;
                    }
                }, // .....end of isValidated()
        }
    };
</script>
<style scoped>
    .v-text-field {
        padding-top: 0px;
        margin-top: 0px;
    }
    
    .v-icon-size {
        font-size: 20px;
    }
    
    .edit-avatar {
        transition: 0.3s;
        border: 1px solid #b0bec5;
    }
    
    .edit-avatar:hover {
        background-color: #b0bec5;
    }
    
    .edit-v-icon {
        padding-left: 6px;
        font-size: 15px;
    }
    
    .edit-v-icon:hover {
        color: white;
    }
</style>